import React from "react"
// import { LocaleContext } from "../components/layout-components/layout"

const NotFoundPage = () => {
  //TODO: make 404 language specific
  // const lang = React.useContext(LocaleContext)

  return (
    <div>
      <p>404 page</p>
    </div>
  )
}

export default NotFoundPage
